import React, { useEffect, useState } from "react";
import { Flex, Box, Badge } from "@chakra-ui/react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

import SEO from "../../components/Seo";
import Nav from "../../components/Nav";
import ReusableFooter from "../../components/ReusableFooter";
import StaticLogo from "../../components/StaticLogo";
import Footer from "../../components/Footer";
import MobileNav from "../../components/MobileNav";

import sd from "../../images/scraped/sodelishus.json";

import "../../styles/casestudies.css";
import Slideshow from "../../components/Slideshow";
import AccomplishmentSlider from "../../components/AccomplishmentSlider";

import sode0 from "../../images/sode/0.png";
import sode1 from "../../images/sode/1.png";
import sode2 from "../../images/sode/2.png";
import sode3 from "../../images/sode/3.png";
import sode4 from "../../images/sode/4.png";
import sode5 from "../../images/sode/5.png";
import sode6 from "../../images/sode/6.png";
import sode7 from "../../images/sode/7.png";
import sode8 from "../../images/sode/8.png";
import sode9 from "../../images/sode/9.png";
import sode10 from "../../images/sode/10.png";
import sode11 from "../../images/sode/11.png";
import sode12 from "../../images/sode/12.png";
import sode13 from "../../images/sode/13.png";
import sode14 from "../../images/sode/14.png";
import sode15 from "../../images/sode/15.png";
import sode16 from "../../images/sode/16.png";
import sode17 from "../../images/sode/17.png";
import sode18 from "../../images/sode/18.png";
import sode19 from "../../images/sode/19.png";
import sode20 from "../../images/sode/20.png";
import sode21 from "../../images/sode/21.png";
import sode22 from "../../images/sode/22.png";

export default function Sodelishus({ location }) {
  const images = [
    { img: sode0 },
    { img: sode1 },
    { img: sode2 },
    { img: sode3 },
    { img: sode4 },
    { img: sode5 },
    { img: sode6 },
    { img: sode7 },
    { img: sode8 },
    { img: sode9 },
    { img: sode10 },
    { img: sode11 },
    { img: sode12 },
    { img: sode13 },
    { img: sode14 },
    { img: sode15 },
    { img: sode16 },
    { img: sode17 },
    { img: sode18 },
    { img: sode19 },
    { img: sode20 },
    { img: sode21 },
    { img: sode22 },
  ];

  const text =
    "Do you have a project in mind? Get An Estimate for your Digital Marketing Campaign";

  const isBrowser = typeof window !== "undefined";

  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const [openGal, setOpenGal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const allimages = sd.map((img) => img);

  useEffect(() => {
    if (isBrowser) {
      updateDimensions();
      window.addEventListener("resize", updateDimensions);
    }

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Box className="main" overflowX="hidden">
      <SEO />
      {width > 1280 ? (
        <Nav loc={location} page="/case_studies" />
      ) : (
        <MobileNav />
      )}
      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mt="80px"
        >
          <Box>
            <h1 className="title">Sodelishus</h1>
          </Box>
          <Box alignSelf="center" py="20px">
            <Badge>Food Company</Badge>
          </Box>
        </Flex>
        <Box w="95%" className="caseStudyBox" m="auto" mt="50px">
          <Box textAlign="center" pt="30px" className="viewshead">
            <h3>sodelishus.co.uk</h3>
            <p>Sodelishus is a health-focused food company</p>
          </Box>
          <Box px="5%" py="36px" className="caseparagraph">
            <p>
              Getting the right ingredients proved difficult. In many areas
              there were no healthy options available at all, so in 2010 Balazs
              and I made the decision to create our own food blending factory to
              be able to create the best tailor-made healthy ingredients and
              supply them to food manufacturers for use in their own healthier
              products. For us this was not just a business, it was vital for my
              health, my wellbeing, my life and that of my family.
            </p>
            <p style={{ marginTop: "30px" }}>
              When we moved here, I was shocked to find there was no range of
              low carb, low sugar, high protein products on the supermarket
              shelves (although low fat and gluten free products were). Crohn’s
              disease is complex, and there are many limitations on what you can
              and can’t eat. SoDelishUs is the answer to this gap and brings
              healthier foods to all families, myself and my family included.”
            </p>
          </Box>
          <Flex flexWrap="wrap">
            <Flex flexWrap="wrap" w="100%" justifyContent="center">
              {images.map((img, i) => {
                return (
                  <Box w={["90%", "90%", "25%", "25%", "25%"]} m="5px">
                    <img
                      key={i}
                      src={img?.img}
                      alt={img}
                      style={{
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenGal(true);
                        setActiveIndex(i);
                      }}
                    />
                  </Box>
                );
              })}
            </Flex>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            my="20px"
            flexWrap="wrap"
          >
            <Box mx="10px" my="5px">
              <button
                className="button button--aylen "
                onClick={() => {
                  window.location.assign("/contact");
                }}
              >
                <span>BOOKING ENQUIRY</span>
              </button>
            </Box>
            <Box mx="10px" my="5px">
              <button
                className="button button--aylen "
                onClick={() => {
                  window.location.assign("/case_studies");
                }}
              >
                <span>VIEW MORE CASE STUDIES</span>
              </button>
            </Box>
          </Flex>
        </Box>
        <AccomplishmentSlider />
        <Slideshow />
        <ReusableFooter text={text} />
        <Footer />
      </Box>
      {openGal ? (
        <Lightbox
          images={allimages?.img}
          startIndex={activeIndex}
          onClose={() => setOpenGal((prevState) => !prevState)}
        />
      ) : null}
    </Box>
  );
}
